import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {downloadData} from "./DataDownloader";
import {PortInfo} from "./PortInfo";
import {FerryAlerts} from "./FerryAlerts";

export function RouteStatus2(props) {
    const [data, setData] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [time, setTime] = useState(new Date());
    const [route, setRoute] = useState(null);
    const [pageTitle, setPageTitle] = useState("Ferry Schedule");

    const navigate = useNavigate();

    let {routeParameter} = useParams();

    if (route === null) {
        if (routeParameter !== undefined) {
            routeParameter = routeParameter.toLowerCase();
            routeParameter = routeParameter.replace("-ferryschedule", "");
            if (routeParameter === "seattle-bainbridge" || routeParameter === "bainbridge-seattle") {
                setRoute("sea-bi");
            } else if (routeParameter === "seattle-bremerton" || routeParameter === "bremerton-seattle") {
                setRoute("sea-br");
            } else if (routeParameter === "edmonds-kingston" || routeParameter === "edmonds-kingston") {
                setRoute("ed-king");
            } else if (routeParameter === "point defiance-tahlequah" || routeParameter === "tahlequah-point defiance") {
                setRoute("pd-tal");
            } else if (routeParameter === "mukilteo-clinton" || routeParameter === "clinton-mukilteo") {
                setRoute("muk-cl");
            }
        } else {
            setRoute("sea-bi");
        }
    }

    let newPageTitle = null;
    if (route === 'sea-bi') {
        newPageTitle = "Seattle Bainbridge Island Ferry Schedule";
    } else if (route === 'sea-br') {
        newPageTitle = "Seattle Bremerton Ferry Schedule";
    } else if (route === "ed-king") {
        newPageTitle = "Edmonds Kingston Ferry Schedule";
    } else if (route === "pd-tal") {
        newPageTitle = "Point Defiance Tahlequah Ferry Schedule";
    } else if (route === "muk-cl")  {
        newPageTitle = "Mukelteo Clinton Ferry Schedule"
    }

    if (newPageTitle !== pageTitle) {
        setPageTitle(newPageTitle);
    }

    useEffect(() => {
        document.title = pageTitle;
    }, [pageTitle]);

    useEffect(() => {
        const interval = setInterval(() => {setTime(new Date());}, 127 * 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect( () => {
        console.log('Downloading fresh data')
        const url = "/api/route_status/" + route;
        downloadData(url, setData, setErrorMessage);
    }, [time, route]);

    if (data == null) {
        return (
            <div className="d-flex justify-content-center m-5">
                <Spinner/>
            </div>
        )
    }

    const lastUpdate = data["lastUpdate"];
    const leftPortInfo = data["leftPort"];
    const rightPortInfo = data["rightPort"];

    const lastUpdateD = new Date(lastUpdate * 1000);

    const timeSinceUpdate = new Date() - lastUpdateD;
    console.log('Time since last update: ');
    console.log(timeSinceUpdate/3600000);

    if (2 * 3600 * 1000 < timeSinceUpdate) {
        return (
        <Container fluid className='mt-4 mb-5 ms-2 me-2'>
            <Row>
                <Col>
                    Not showing predicted schedule.<br/>
                    Data from server is more than 2 hours old.
                </Col>
            </Row>
        </Container>
        )
    }

    const lastUpdateString =   lastUpdateD.toLocaleDateString() + "  " + lastUpdateD.toLocaleTimeString();

    function onRouteChange(e) {
        let newRoute = e.target.value;
        setRoute(newRoute);

        if (newRoute === "sea-bi") {
            navigate('/Seattle-Bainbridge');
        } else if (newRoute === "sea-br") {
            navigate('/Seattle-Bremerton');
        } else if (newRoute === "ed-king") {
            navigate('/Edmonds-Kingston');
        } else if (newRoute === "pd-tal") {
            navigate('/Point Defiance-Tahlequah');
        } else if (newRoute === "muk-cl") {
            navigate('/Mukilteo-Clinton');
        }
    }

    return (
        <Container fluid className='mt-4 mb-5 ms-2 me-2'>
            <FerryAlerts alerts={data["alerts"]}/>
            <Row className='g-4'>
                <Col sm={6} lg={5} xl={4}>
                    <PortInfo portInfo={leftPortInfo}/>
                </Col>
                <Col sm={6} lg={5} xl={4}>
                    <PortInfo portInfo={rightPortInfo}/>
                </Col>
            </Row>
            <Row>
                <Col className='mt-2 mb-2' xs={12} sm={6} lg={4}>
                    <Form.Select aria-label='A label' value={route} onChange={onRouteChange}>
                        <option value="sea-bi">Seattle - Bainbridge</option>
                        <option value="sea-br">Seattle - Bremerton</option>
                        <option value="ed-king">Edmonds - Kingston</option>
                        <option value="pd-tal">Point Defiance - Tahlequah</option>
                        <option value="muk-cl">Mukilteo - Clinton</option>
                    </Form.Select>
                </Col>
                <Col>

                </Col>
            </Row>
            <Row>
                <Col className='mb-2 ms-2'><small>Last update: {lastUpdateString}</small></Col>
            </Row>
            <Row>
                <Col><a href='/about.html'>About</a></Col>
            </Row>
        </Container>
    )
}
