import {Departure} from "./Departure";
import {Card} from "react-bootstrap";

export function MostRecentDepartureSummary(props) {
    const portInfo = props.departure;

    let message = (<>&nbsp;</>);

    if (portInfo !== null && portInfo.availableSpacePercent !== undefined && portInfo.availableSpacePercent !== null) {
        const percentFull = 100 - portInfo.availableSpacePercent;

        let textColor = '';

        if (90 < percentFull) {
            textColor = 'text-danger';
        }

        message = (<span className={textColor}>Last departure was {percentFull}% full (cars)</span>);
    }

    return (<><p className='mb-1'>{message}</p></>);
}

export function PortInfo(props) {

    const portInfo = props.portInfo;

    return (
        <Card className='mt-2 mb-2 p-2'>
            <h5>{portInfo.portName}</h5>
            <MostRecentDepartureSummary departure={portInfo.mostRecentDeparture}/>
            {portInfo.upcomingDepartures.map(departure => (<Departure departure={departure}/>))}
        </Card>
    )
}