import {Badge, Card, CardBody, CardFooter, CardTitle, Col, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCar} from "@fortawesome/free-solid-svg-icons";

export function CountDown(props) {
    const [time, setTime] = useState(new Date());
    useEffect(() => {
        const interval = setInterval(() => {setTime(new Date());}, 1000);
        return () => clearInterval(interval);
    }, []);

    const endTime = props.endTime;
    const now_epoch = Math.floor(new Date().getTime()/1000.0);
    let timeUntilDepartureSeconds = endTime - now_epoch;

    if (timeUntilDepartureSeconds < 0) {
        timeUntilDepartureSeconds = 0;
    }

    const hours = (Math.floor(timeUntilDepartureSeconds/3600)).toString().padStart(2, '0');
    const minutes = (Math.floor(timeUntilDepartureSeconds/60) % 60).toString().padStart(2, '0');
    const seconds = (timeUntilDepartureSeconds % 60).toString().padStart(2, '0');

    if (hours === 0) {
        return (<>{minutes}:{seconds}</>);
    } else {
        return (<>{hours}:{minutes}:{seconds}</>);
    }
}

export function Departure(props) {
    const [time, setTime] = useState(new Date());
    useEffect(() => {
        const interval = setInterval(() => {setTime(new Date());}, 3000);
        return () => clearInterval(interval);
    }, []);

    const departure = props.departure;

    let delay = 'On time';
    let cardBackground = 'success';

    let departureTimeEpoch = departure.departureTime;

    if (300 < departure.delayInSeconds) {
        const delayInMinutes = (departure.delayInSeconds / 60.0).toFixed(0);
        const edt_epoch = departure.departureTime + departure.delayInSeconds;
        const edt_string = new Date(edt_epoch * 1000).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
        delay = (<><strong>ETD: {edt_string}</strong> ({delayInMinutes} minute delay)</>)
        cardBackground = 'warning';
        departureTimeEpoch = departure.departureTime + departure.delayInSeconds;
    } else if (120 <= departure.delayInSeconds) {
        delay = 'On time (small delay)';
    }

    const scheduledDepartureTime = new Date(departure.departureTime * 1000).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });

    const now_epoch = Math.floor(new Date().getTime()/1000.0);
    if (departureTimeEpoch < now_epoch) {
        cardBackground = 'secondary'
    }

    let atDock = null;
    if (departure.atDock) {
        atDock = '(at dock)'
    }

    let autoPercentFull = null;

    if (departure.availableSpacePercent !== null && departure.availableSpacePercent < 100) {
        let autoBadgeBackground = 'success';
        if (departure.availableSpacePercent < 15) {
            autoBadgeBackground = 'danger';
        } else if (departure.availableSpacePercent < 40) {
            autoBadgeBackground = 'warning';
        }
        autoPercentFull = (<Badge pill bg={autoBadgeBackground}><FontAwesomeIcon icon={faCar} /> {departure.availableSpacePercent}% available</Badge>)
    }

    return (
        <Card border={cardBackground} className='mb-4 border-2'>
            <CardBody>
                <CardTitle>{scheduledDepartureTime}</CardTitle>
                {departure.vesselName} {atDock} {autoPercentFull}
            </CardBody>
            <CardFooter>
                <Row>
                    <Col>
                        {delay}<br/>
                        Leaves in <CountDown endTime={departureTimeEpoch}/>
                    </Col>
                </Row>
            </CardFooter>
        </Card>
    );
}