
export function downloadData(url, setServerData, setErrorMessage, additional_json_data) {

    console.log('downloadData()  url: ' + url);

    const clientSecret = localStorage.getItem('clientSecret');

    if (additional_json_data === undefined) {
        additional_json_data = {};
    }

    const data = {
        client_secret: clientSecret,
        ...additional_json_data
    };

    fetch(url,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(response => {
            console.log('Received response for ' + url);
            if (response.status === 403) {
                console.log("403: " + url);
                window.location.href = '/request_access';
            } else if (response.status === 404) {
                console.log("404 (Not Found): " + url);
                throw Error('Data not available')
            } else {
                return response.json()
            }
        })
        .then(response => {
            setServerData(response);
        })
        .catch(error => {
            console.log('Error while fetching data...');
            console.log(error);
            setErrorMessage('' + error);
        })
}
