import {useState} from "react";
import {Accordion, Button, Col, Row} from "react-bootstrap";
import dayjs from "dayjs";

export function FerryAlert(props) {
    const [refreshCount, setRefreshCount] = useState(0);
    const alert = props.alert;

    let clearedIds = JSON.parse(localStorage.getItem("clearedBulletinIds"));
    if (clearedIds === null || clearedIds === undefined) {
        clearedIds =  [];
    }

    const strBulletinId = alert.bulletinId.toString();
    if (clearedIds.find((x) => x === strBulletinId) !== undefined) {
        return (<></>);
    }

    let relativeTime = require('dayjs/plugin/relativeTime');
    dayjs.extend(relativeTime);

    const age = dayjs(alert.publishDate * 1000).fromNow();

    return (
        <Accordion.Item eventKey={alert.bulletinId}>
            <Accordion.Header>
                {alert.title}
                <span className='flex-grow-1'></span>
                <span className='me-2'>{age}</span>
            </Accordion.Header>
            <Accordion.Body>
                <div dangerouslySetInnerHTML={{ __html: alert.message }} />
                <Button onClick={onClearClick} value={alert.bulletinId} size="sm" className='mt-2'>Clear</Button>
            </Accordion.Body>
        </Accordion.Item>
    )

    function onClearClick(e) {
        console.log(e);
        let clearedIds = JSON.parse(localStorage.getItem("clearedBulletinIds"));
        if (clearedIds === null || clearedIds === undefined) {
            clearedIds =  [];
        }
        clearedIds.push(e.target.value);
        localStorage.setItem("clearedBulletinIds", JSON.stringify(clearedIds));
        setRefreshCount(refreshCount + 1);
    }
}

export function FerryAlerts(props) {

    const alerts = props.alerts;

    return (
        <Row>
            <Col sm={12} lg={10} xl={8}>
                <Accordion className='mb-2'>
                    {alerts.map(alert => (<FerryAlert alert={alert}/>))}
                </Accordion>
            </Col>
        </Row>
    );
}