import {createBrowserRouter} from "react-router-dom";
import React from "react";
import {ErrorPage} from "./ErrorPage";
import {RouteStatus2} from "./RouteStatus2";
import {About} from "./about";

const router = createBrowserRouter([
    {
        path: "/about.html",
        element: <About/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/About.html",
        element: <About/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/",
        element: <RouteStatus2/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/:routeParameter",
        element: <RouteStatus2/>,
        errorElement: <ErrorPage/>
    },
]);

export default router;
