import {Col, Container, Row} from "react-bootstrap";

export function About(props) {
    return (<Container fluid className='mt-4 mb-5 ms-2 me-2'>
<Row>
    <Col className='m-5'>
<h2>Ferry Watch</h2>
        <p>Ferry Watch was created so that frequent ferry travelers can quickly determine how late the ferries are running. It lists the next two sailings and
predicts, based on data from the Washington State Ferries site, the actual departure time.</p>

        <p>Drivers can get an idea of how long the wait for cars is from the remaining spaces for a sailing, and
            how full (of cars) the previous departure was.</p>

Predictions are made for the following routes:
        <ul>
            <li><a href="https://ferry-watch.com/Seattle-Bainbridge">Seattle Bainbridge Ferry</a></li>
            <li><a href="https://ferry-watch.com/Seattle-Bremerton">Seattle Bremerton Ferry</a></li>
            <li><a href="https://ferry-watch.com/Edmonds-Kingston">Kingston Edmonds Ferry</a></li>
            <li><a href="https://ferry-watch.com/Point%20Defiance-Tahlequah">Point Defiance - Tahlequah Ferry</a></li>
            <li><a href="https://ferry-watch.com/Mukilteo-Clinton">Mukilteo Clinton Ferry</a></li>
        </ul>

Predicting departure times for ferries in the San Juan Islands is difficult and those routes have been omitted.

    </Col>
</Row>

    </Container>)
}
